.loader-background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;

  .loader {
    position: absolute;
    /* border: 16px solid #f3f3f3; */
    /* border-radius: 50%; */
    /* border-top: 16px solid #3498db; */
    width: 203px;
    height: 203px;
    margin-top: calc(50vh - 101px);
    margin-left: calc(50% - 101px);
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;

    @media (max-width: 750px) {
      width: 100px;
      height: 100px;
      margin-top: calc(50vh - 50px);
      margin-left: calc(50% - 50px);
    }
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}