body {
  /* overflow-y: scroll; */
  /* for Internet Explorer, Edge */
  /* -ms-overflow-style: none;  */
  /* for Firefox */
  /* scrollbar-width: none;
  overflow-y: scroll;  */
  background-color: #000000;
}

body::-webkit-scrollbar {
    /* for Chrome, Safari, and Opera */
  /* display: none;  */
}

* {
  color: white;
  font-family: 'Manrope';
}