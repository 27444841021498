.contact-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0;
  margin-top: 50px;
  background-color: #000000;

  @media (max-width: 750px) {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .contact-title {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 120%;
    margin-top: 50px;
    margin-bottom: 26px;
    text-align: center;

    @media (max-width: 750px) {
      margin-top: 30px;
      margin-bottom: 15px;
      font-size: 27px;
    }
  }

  .contact-input {
    max-width: 768px;
    width: 80%;
    padding: 17px 28px;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);
    border-radius: 4px;
    font-size: 18px;
    color: #000000;

    @media (max-width: 750px) {
      width: 80%;
      height: 65px;
      font-size: 14px;
      padding: 0px 23px;
    }
  }

  .contact-text-area {
    margin-top: 20px;
    height: 120px;
    background: rgba(255, 255, 255, 0.9);
    padding: 17px 28px;
    margin-bottom: 10px;
    border-radius: 4px;
    max-width: 768px;
    width: 80%;

    @media (max-width: 800px) {
      height: 100px;
      padding: 15px 23px;
      border-radius: 4px;
    }

    textarea {
      height: 100%;
      width: 100%;
      background: none;
      color: #000000;
      border: none;
      outline: none;
      resize: none;
      font-size: 18px;

      @media (max-width: 800px) {
        font-size: 14px;
      }
    }
  }

  .contact-mandatory {
    margin-top: 0;
    text-align: left;
    max-width: 768px;
    width: 80%;
    margin-left: -50px;

    @media (max-width: 800px) {
      width: 80%;
    }
  }

  .contact-send-button {
    background: #33e13a;
    border-radius: 33px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 175%;
    cursor: pointer;
    margin-top: 20px;
    padding: 13px 38px;

    @media (max-width: 800px) {
      padding: 18px 50px;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      border-radius: 28px;
    }
  }

  button:disabled {
    opacity: 0.5;
  }

  button:disabled:hover {
    cursor: not-allowed;
  }

  .directly-block {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 30px;

    .contact-email-title {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 115%;
      margin: 0;

      @media (max-width: 800px) {
        font-size: 15px;
      }
    }

    .contact-email {
      margin: 0;
      font-style: normal;
      font-weight: 800;
      font-size: 29px;
      line-height: 120%;
      color: #33e13a;

      @media (max-width: 800px) {
        font-size: 19px;
      }
    }
  }
}
